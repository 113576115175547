import React from 'react';
import { Container, Segment, Header, Placeholder, Button, Label, List, Breadcrumb, Dropdown, Icon } from 'semantic-ui-react';
import moment from 'moment-timezone';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { setCurrentView } from '../../redux/view/view.actions';

import { selectCurrentCompany } from '../../redux/company/company.selectors';
import { getEmployeesStart } from '../../redux/employees/employees.actions';
import { selectCurrentEmployees, selectEmployeesLoading } from '../../redux/employees/employees.selectors';

import { firestore } from '../../firebase/firebase.utils';

import { getShiftsStart } from '../../redux/shifts/shifts.actions';
import { selectCurrentShifts, selectShiftsLoading, selectShiftsErrors } from '../../redux/shifts/shifts.selectors';

import { selectSurveysLoading, selectCurrentSurveys, selectSurveysErrors, selectCurrentPrompts, selectCurrentResponses } from '../../redux/surveys/surveys.selectors';
import { getPromptsStart } from '../../redux/surveys/surveys.actions';

class ResponseView extends React.Component {
  state = {
    promptId: this.props.view.viewData.promptId
  }

  componentDidMount() {
    const { currentCompany, getShiftsStart, getEmployeesStart } = this.props;
    getEmployeesStart({ currentCompany });
    getShiftsStart({ currentCompany });
    this.subscribeToResponses(currentCompany);
  }

  subscribeToResponses = (currentCompany) => {
    const { getPromptsStart } = this.props;
    const companyId = currentCompany.companyId;
    console.log(`Subscribing to responses for company ${companyId}`);
    this.unsubscribeToResponses = firestore.collection('responses').where("linkedCompany", "==", companyId).onSnapshot(function(querySnapshot) {
      console.log("Responses received");
      getPromptsStart(currentCompany);
    });
  }

  componentWillUnmount(){
    console.log("Unsubscribing from responses");
    this.unsubscribeToResponses();
  }

  getLastResponseAgo = (id) => {
    const { currentResponses } = this.props;
    if (!currentResponses) return "a few minutes ago";
    let mostRecentTime = null;
    Object.keys(currentResponses).forEach(responseId => {
      const response = currentResponses[responseId]; 
      if (response) {
        if (response.employeeId === id) {
          const responseTime = response.responseTime.toDate();
          if (!mostRecentTime) mostRecentTime = responseTime;
          if (responseTime > mostRecentTime) mostRecentTime = responseTime;
        }
      }
    });
    if (!mostRecentTime) return "a few minutes ago";
    return moment(new Date(mostRecentTime)).fromNow();
  }

  renderEmployeeDetails = (id) => {
    const { employeesLoading, currentEmployees } = this.props;
    return (
      (employeesLoading || !currentEmployees) ? (<Placeholder><Placeholder.Header><Placeholder.Line /></Placeholder.Header></Placeholder>) : 
      (currentEmployees[id] ? currentEmployees[id].employeeName : "[Removed Employee]")
    );
  }

  renderPreviousPrompts = () => {
    const { currentPrompts } = this.props;
    if (!currentPrompts) return null;
    if (!Object.keys(currentPrompts).length) return null;
    const promptsToSort = Object.keys(currentPrompts).map(id => currentPrompts[id]);
    const sortedPrompts = promptsToSort.sort((a,b) => b.promptTime.toMillis() - a.promptTime.toMillis());
    const dropdownOptions = sortedPrompts.map(promptSel => {
      const promptSelDate = moment(new Date(promptSel.promptTime.toDate())).format("MMMM Do YYYY, h:mm A z");
      return ({
        key: promptSel.promptId,
        text: `results for ${promptSelDate}`,
        value: promptSel.promptId,
        content: <React.Fragment>{promptSelDate}<Label size="mini">{promptSel.promptType.charAt(0).toUpperCase() + promptSel.promptType.slice(1)}</Label></React.Fragment>
      })
    });

    return (<Dropdown
      inline
      header='View Previous Results'
      options={dropdownOptions}
      defaultValue={dropdownOptions[0].value}
      onChange={(e, data) => this.setState({ promptId: data.value })}
    />);
  }

  render() {
    const { setCurrentView, currentPrompts, shiftsLoading, surveysLoading } = this.props;
    const { promptId } = this.state;
    const promptData = currentPrompts[promptId];
    const clearedForWork = promptData.clearedForWork || [];
    const atRisk = promptData.employeesAtRisk || [];
    const noResponse = (promptData.notifiedEmployeeIds || []).filter(id => !(clearedForWork.includes(id) || atRisk.includes(id)));

    return (
      <Container>
        <Breadcrumb>
          <Breadcrumb.Divider icon='left chevron' />
          <Breadcrumb.Section link onClick={() => setCurrentView("ResponseList")}>Back</Breadcrumb.Section>
        </Breadcrumb>

        <Header as="h1">
          <Header.Content>COVID-19 Self-Screening Survey</Header.Content>
          <Header.Subheader>Survey Results
            {/* <Label color="black">Automated</Label> */}
          </Header.Subheader>
        </Header>

        <Header as='h4'>
          <Icon name='calendar alternate' />
          <Header.Content>
            Showing {' '}
            {this.renderPreviousPrompts()}
          </Header.Content>
        </Header>

        {/* <Segment placeholder>
          <Header icon>
            <Icon name='excel outline' />
            No users
          </Header>
          <Button primary>Upload .csv file</Button>
        </Segment> */}

        <Segment.Group>
          <Segment>
            <Header>
              <Header.Content>Survey Recipients { (promptData.promptType && promptData.promptTime) ? (
                  <Label style={{ marginLeft: '6px' }} image basic>
                    <Icon name='stopwatch' />
                    Last Triggered ({promptData.promptType.charAt(0).toUpperCase() + promptData.promptType.slice(1)})
                    <Label.Detail>{moment(new Date(promptData.promptTime.toDate())).format("MMMM Do YYYY, h:mm A z")}</Label.Detail>
                  </Label>
                ) : null}</Header.Content>
              {/* <Header.Subheader>Automated survey sent 15 minutes ago</Header.Subheader> */}
            </Header>
          </Segment>
          <Segment loading={shiftsLoading || surveysLoading}>

            { atRisk.length ? (
              <React.Fragment>

                <Header>Potentially at Risk</Header>

                <List divided verticalAlign='middle' animated>
                  {atRisk.map(id => (
                    <List.Item key={id}>
                      <List.Content floated='right'>
                        {/* <Button content="Contact" icon="phone" labelPosition="left"/> */}
                        <Button onClick={() => setCurrentView("EmployeeEdit", { employeeId: id })}>Manage</Button>
                      </List.Content>
                      <List.Icon name='exclamation triangle' color="orange" verticalAlign='middle' />
                      <List.Content>
                        <List.Header>{this.renderEmployeeDetails(id)}</List.Header>
                        <List.Description>Flagged risk factors {this.getLastResponseAgo(id)}</List.Description>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </React.Fragment>
            ) : null}

            { noResponse.length ? (
              <React.Fragment>
                <Header>Not Yet Completed</Header>

                <List divided verticalAlign='middle' animated>
                  {noResponse.map(id => (
                    <List.Item key={id}>
                      <List.Content floated='right'>
                        {/* <Button content="Resend" icon="redo" labelPosition="left"/> */}
                        <Button onClick={() => setCurrentView("EmployeeEdit", { employeeId: id })}>Manage</Button>
                      </List.Content>
                      <List.Icon name='question circle' verticalAlign='middle' />
                      <List.Content>
                        <List.Header>{this.renderEmployeeDetails(id)}</List.Header>
                        <List.Description>Survey sent {moment(new Date(promptData.promptTime.toDate())).fromNow()}</List.Description>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </React.Fragment>
            ) : null}

            { clearedForWork.length ? (
              <React.Fragment>

                <Header>Ready for Work</Header>

                <List divided verticalAlign='middle' animated>
                  {clearedForWork.map(id => (
                    <List.Item key={id}>
                      <List.Content floated='right'>
                        {/* <Button content="Resend" icon="redo" labelPosition="left"/> */}
                        <Button onClick={() => setCurrentView("EmployeeEdit", { employeeId: id })}>Manage</Button>
                      </List.Content>
                      <List.Icon name='check circle' color="green" verticalAlign='middle' />
                      <List.Content>
                        <List.Header>{this.renderEmployeeDetails(id)}</List.Header>
                        <List.Description>Checked in {this.getLastResponseAgo(id)}</List.Description>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </React.Fragment>
            ) : null}

          </Segment>
          <Segment secondary>
            {/* <Button content="Download Responses" labelPosition="left" icon="download"/> */}
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  view: selectCurrentViewWithData,
  currentCompany: selectCurrentCompany,
  currentShifts: selectCurrentShifts,
  shiftsErrors: selectShiftsErrors,
  shiftsLoading: selectShiftsLoading,
  surveysLoading: selectSurveysLoading,
  currentSurveys: selectCurrentSurveys,
  surveysErrors: selectSurveysErrors,
  currentPrompts: selectCurrentPrompts,
  currentResponses: selectCurrentResponses,
  currentEmployees: selectCurrentEmployees,
  employeesLoading: selectEmployeesLoading
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  getEmployeesStart: ({ currentCompany }) => dispatch(getEmployeesStart({ currentCompany })),
  getPromptsStart: (linkedCompany) => dispatch(getPromptsStart(linkedCompany)),
  getShiftsStart: ({ currentCompany }) => dispatch(getShiftsStart({ currentCompany }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponseView);